html {
	background: url(../images/background.jpg) no-repeat center center fixed;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}
html, body{
	height:100%;
	margin:0;
	padding:0
}
body{
	background: transparent;
}
.container-fluid{
	height:100%;
	display:table;
	width: 100%;
	padding: 0;
}
.row-fluid {height: 100%; display:table-cell; vertical-align: middle;}

.login-form{
	width: 350px;
	float:none;
	margin:0 auto;
	// margin-top: 50px;
	background: #fff;
	border-radius: 3px;
	h2{
		background:  #eee;
		font-size: 20px;
		padding: 10px;
		text-align: center;
		border-radius: 3px 3px 0 0 ;

	}
	form{
		padding: 20px;
		.input-wrapper{
			position: relative;
			i{
				position: absolute;
				top: 7px;
				right: 8px;
				font-size: 18px;
				color: #888;
			}
			input{
				padding-right: 27px;
			}
		}
	}
}